import PropTypes from 'prop-types'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { Html5Entities } from 'html-entities'

import { LIVE_SITE_URL, DEFAULT_OG_IMAGE } from '@constants'
import uppercaseFirst from 'helpers/uppercaseFirst'

const htmlEntities = new Html5Entities()

function htmlDecode(string = '') {
  return htmlEntities.decode(string)
}

function WordpressSEO({ url, title, seo, image, desc, canonical = null }) {
  const router = useRouter()
  return (
    <NextSeo
      title={
        seo && seo.title
          ? htmlDecode(seo.title)
          : `${htmlDecode(title)}${
              router.query?.site
                ? ` | Select ${uppercaseFirst(router.query.site)}`
                : ''
            } | Select Model Management`
      }
      description={seo?.metaDesc || desc || `Select Model Management`}
      openGraph={{
        title: seo?.title,
        url: `${LIVE_SITE_URL}${url}`,
        description: seo?.metaDesc,
        images: [
          {
            url: image || seo?.images?.[0]?.url || DEFAULT_OG_IMAGE,
          },
        ],
      }}
      canonical={canonical}
    />
  )
}

WordpressSEO.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  image: PropTypes.string,
  canonical: PropTypes.string,
  seo: PropTypes.object,
}
WordpressSEO.defaultProps = {
  url: '',
  title: '',
  desc: null,
  image: '',
  seo: {},
  canonical: null,
}

export default WordpressSEO
